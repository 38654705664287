import { isNil, isString } from "lodash";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isBlank(value: any): boolean {
  if (isNil(value)) return true;
  if (isString(value) && /^\s*$/.test(value)) return true;
  return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isNotBlank(value: any): value is string {
  return isString(value) && value.trim() !== "";
}
