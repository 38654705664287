import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";

export function validateContactEmailUnique(emailAddress: string): Promise<boolean> {
  return apolloClient
    .query({
      query: gql`
        query ($accountUuid: ID!, $emailAddress: String!) {
          validateContactEmailUnique(accountUuid: $accountUuid, emailAddress: $emailAddress)
        }
      `,
      variables: {
        accountUuid: store.getters.accountUuid,
        emailAddress
      }
    })
    .then(response => {
      return response.data.validateContactEmailUnique;
    });
}
