import "@/styles/styles.scss";
import "./plugins/filter-console";
import Vue, { VNode, provide } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import "./plugins/class-component-hooks";
import App from "./App.vue";
import { initHighcharts } from "./plugins/highcharts";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { apolloProvider, apolloClient } from "./plugins/vue-apollo";
import i18n from "./plugins/i18n";
import "./plugins/vee-validate";
import GmapVue from "gmap-vue";
import { components } from "gmap-vue";
import "leaflet/dist/leaflet.css";
import { Auth0Plugin } from "./plugins/auth0";
import linkify from "vue-linkify";

Vue.config.productionTip = false;

// eslint-disable-next-line
if (!process.env.VUE_APP_GOOGLE_MAP_API_KEY) console.error("missing google api key");

Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY
  }
});

const { Cluster } = components;
Vue.component("GmapCluster", Cluster);

initHighcharts();

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_GRAPHQL_HTTP,
  redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
  onRedirectCallback: (appState: any) => {
    router.replace(appState && appState.targetPath ? appState.targetPath : window.location.pathname);
  }
});

Vue.directive("linkified", linkify);

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  i18n,
  render: (h): VNode => h(App)
}).$mount("#app");
