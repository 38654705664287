import { isEmpty, isNil } from "lodash";
import { installRule } from "./utils";
import { compareTimes, formatTimeStrAsAmPm } from "@/utils/date";
import { isBlank, isNotBlank } from "@/utils/string";
import { UniquenessValidatorFn } from "@/types";

const IP_ADDRESS_REGEX =
  /^\s*(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*$/;

const E164_PHONE_NUMBER_REGEX = /^\+[1-9]\d{10,14}$/;

installRule("ip_address", (value: string | number) => {
  return isBlank(value) ? true : IP_ADDRESS_REGEX.test(value.toString());
});

installRule("latitude", (value: string | number): boolean => {
  return isBlank(value) ? true : Number(-90) <= Number(value) && Number(90) >= Number(value);
});

installRule("longitude", (value: string | number): boolean => {
  return isBlank(value) ? true : Number(-180) <= Number(value) && Number(180) >= Number(value);
});

installRule("greater_rel", {
  validate(value, { lowerValue }: Record<string, any>) {
    return isBlank(value) ? true : Number(value) > Number(lowerValue);
  },
  params: ["lowerValue"]
});

installRule("array_filled", {
  validate(value: any[]) {
    return {
      required: true,
      valid: value.every(v => !isBlank(v))
    };
  },
  computesRequired: true
});

installRule("option_selected", {
  validate(value: any) {
    return {
      required: true,
      valid: !isNil(value) && (!isBlank(value.text) || !isBlank(value.id))
    };
  },
  computesRequired: true
});

installRule("phone_number", (value: string | number) => {
  return isBlank(value) ? true : E164_PHONE_NUMBER_REGEX.test(value.toString());
});

installRule("dev_eui", (value: string) => {
  const regExp = /^[0-9A-Fa-f]+$/;
  return value.length === 16 && regExp.test(value);
});

installRule("greater_than_time", {
  validate(value, { lowerValue }: Record<string, any>) {
    return isBlank(value) || isBlank(lowerValue) ? true : compareTimes(value, lowerValue) > 0;
  },
  params: ["lowerValue"],
  formatValue: formatTimeStrAsAmPm,
  formatParams: {
    lowerValue: formatTimeStrAsAmPm
  }
});

installRule("greater_than_equal_time", {
  validate(value, { lowerValue }: Record<string, any>) {
    return isBlank(value) || isBlank(lowerValue) ? true : compareTimes(value, lowerValue) >= 0;
  },
  params: ["lowerValue"],
  formatValue: formatTimeStrAsAmPm,
  formatParams: {
    lowerValue: formatTimeStrAsAmPm
  }
});

interface UniqueStringParams {
  otherValues?: string[];
  validator?: UniquenessValidatorFn;
  originalValue: string | null;
}

installRule("unique_string", {
  validate(value, params) {
    const { otherValues, validator, originalValue } = params as UniqueStringParams;

    if (!isNotBlank(value)) return true;
    const valueStr = value.toLowerCase();

    if (isNotBlank(originalValue) && originalValue.toLowerCase() === valueStr) {
      return true;
    }

    if (otherValues) {
      if (isEmpty(otherValues)) return true;
      return otherValues.every(v => v.toLowerCase() !== valueStr);
    } else if (validator) {
      return validator(valueStr);
    } else {
      return true;
    }
  },
  params: ["otherValues", "validator", "originalValue"]
});
