import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";

const ITEM_PROPERTIES = ["temp_c", "rh_pct", "co2_ppm"];

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "iaq",
  components: {
    AssetDashboardTab: {
      component: () => import("@/iaq/components/DeviceDashboardTab.vue"),
      props: {
        itemProperties: ITEM_PROPERTIES,
        graphProperties: ITEM_PROPERTIES
      }
    },
    AssetInspectorStatus: {
      component: () => import("@/components/AssetInspectorStatus.vue"),
      props: {
        itemProperties: ITEM_PROPERTIES
      }
    }
  },
  categoryProperties: {
    temperature: { property: "temp_c" },
    humidity: { property: "rh_pct" },
    co2: { property: "co2_ppm" }
  },
  properties: {
    temp_c: {
      unit: "degrees_c",
      aggregation: "AVERAGE",
      fitBounds: true,
      unitSelectorFn: () => "degrees_f",
      altUnits: {
        degrees_f: {
          min: -4,
          max: 140,
          format: "integer",
          graphFormat: "decimal1dd"
        }
      },
      comparable: true
    },
    rh_pct: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct",
      aggregation: "AVERAGE",
      comparable: true
    },
    co2_ppm: {
      min: 400,
      max: 5000,
      format: "integer",
      unit: "ppm",
      aggregation: "MAXIMUM",
      comparable: true
    }
  }
});

export default config;
