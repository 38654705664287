import { computed, unref } from "vue";
import { useSubscription } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DecoratedAsset, MaybeRef } from "@/types";
import { updateAssetProperties } from "@/config/asset";
import store from "@/store";
import { stateChange } from "@/gql/fragments/state-change";

export function useAssetSubscription(assets: MaybeRef<DecoratedAsset[] | undefined>): void {
  const assetUuids = computed(() => unref(assets)?.map(a => a.assetUuid) ?? []);
  const accessToken = computed<string | null>(() => store.getters.accessToken);

  const { onResult: onSubscriptionResult } = useSubscription(
    gql`
      ${stateChange}
      subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
        assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
          assetUuid
          property
          stamp
          value
          ...StateChangeFields
        }
      }
    `,
    {
      assetUuids,
      authorization: accessToken
    },
    () => ({
      enabled: !!accessToken.value && assetUuids.value.length > 0
    })
  );

  onSubscriptionResult(({ data }) => {
    const actualAssets = unref(assets);
    if (actualAssets && data?.assetDataChanges) {
      updateAssetProperties(actualAssets, data.assetDataChanges);
    }
  });
}
