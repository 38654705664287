import Vue from "vue";
import { NormalizedScopedSlot, ScopedSlotChildren } from "vue/types/vnode";
import router from "@/router";

let rendererComponent: ReturnType<typeof Vue.extend>;

function createRendererComponent(): ReturnType<typeof Vue.extend> {
  return Vue.extend({
    router,
    props: {
      vnodes: { type: Array, required: true }
    },
    render: function (h) {
      return h("div", this.vnodes as ScopedSlotChildren);
    }
  });
}

export function renderScopedSlot(slot: NormalizedScopedSlot | undefined, props: Record<any, any>): string | undefined {
  if (slot === undefined) return undefined;

  const vnodes = slot(props);
  if (vnodes === undefined) return undefined;

  // Delay creation of renderer component until first use, because
  // it can't be created until the Vue app is initialized.
  if (!rendererComponent) rendererComponent = createRendererComponent();
  const renderer = new rendererComponent({
    propsData: { vnodes }
  });

  renderer.$mount();
  return renderer.$el.innerHTML;
}
