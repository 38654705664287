import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";

const DISPLAYED_PROPERTIES = ["temp_c", "rh_pct", "co2_ppm", "tvoc_ppb"];

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "iaq",
  components: {
    AssetDashboardTab: {
      component: () => import("@/iaq/components/DeviceDashboardTab.vue"),
      props: {
        itemProperties: DISPLAYED_PROPERTIES,
        graphProperties: DISPLAYED_PROPERTIES
      }
    },
    AssetInspectorStatus: {
      component: () => import("@/components/AssetInspectorStatus.vue"),
      props: {
        itemProperties: DISPLAYED_PROPERTIES
      }
    }
  },
  categoryProperties: {
    temperature: { property: "temp_c" },
    humidity: { property: "rh_pct" },
    co2: { property: "co2_ppm" },
    tvoc: { property: "tvoc_ppb" }
  },
  properties: {
    temp_c: {
      unit: "degrees_c",
      aggregation: "AVERAGE",
      fitBounds: true,
      unitSelectorFn: () => "degrees_f",
      altUnits: {
        degrees_f: {
          min: 32,
          max: 120,
          format: "integer",
          graphFormat: "decimal1dd"
        }
      },
      comparable: true
    },
    rh_pct: {
      min: 0,
      max: 100,
      format: "integer",
      unit: "pct",
      aggregation: "AVERAGE",
      comparable: true
    },
    co2_ppm: {
      min: 0,
      max: 3000,
      format: "integer",
      unit: "ppm",
      aggregation: "MAXIMUM",
      comparable: true
    },
    tvoc_ppb: {
      min: 0,
      max: 2000,
      format: "integer",
      unit: "ppb",
      aggregation: "MAXIMUM",
      comparable: true
    }
  }
});

export default config;
