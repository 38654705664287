import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";
import { BuildingFields, GqlGeocodingResult } from "@/types";

interface CreateBuildingResult {
  buildingUuid: string;
  geocodingResult: GqlGeocodingResult;
}

export default function (groupUuids: string[], parameters: BuildingFields): Promise<CreateBuildingResult> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $groupUuids: [ID!]!, $parameters: CreateBuildingConfig!) {
          createBuilding(accountUuid: $accountUuid, groupUuids: $groupUuids, parameters: $parameters) {
            buildingUuid
            geocodingResult {
              lat
              lng
              timeZone
              success
            }
          }
        }
      `,
      variables: {
        accountUuid: store.getters.accountUuid,
        groupUuids: groupUuids,
        parameters: parameters
      }
    })
    .then(response => {
      return response.data.createBuilding;
    });
}
