import { computed, ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { Asset, ChildLink, DecoratedAsset, GqlAsset, LinkSelectorOrBoolean, MaybeRef, ParentLink } from "@/types";
import { decorateAsset, getChildAssets, getParentAssets } from "@/config/asset";
import { deviceLock } from "@/gql/fragments/deviceLock";
import { useAssetSubscription } from "./use-asset-subscription";
import { compact, isBoolean } from "lodash";

type PropertyCategories = Record<string, boolean | null | undefined>;

export interface UseBuildingAssetsOptions {
  includeFloorAssets?: MaybeRef<boolean>;
  includeAssetHealth?: MaybeRef<boolean>;
  includePropertyCategories?: MaybeRef<PropertyCategories>;
  onlyKnownAssets?: MaybeRef<boolean>;
  requiredProperties?: MaybeRef<string[]>;
  knownAssetUuids?: MaybeRef<string[]>;
  startDate?: MaybeRef<DateTime | null | undefined>;
  endDate?: MaybeRef<DateTime | null | undefined>;
  enabled?: MaybeRef<boolean>;
  subscribe?: boolean;
}

export interface UseBuildingAssetsResult {
  assets: Ref<DecoratedAsset[] | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useBuildingAssets(
  buildingUuid: MaybeRef<string | null>,
  {
    includeFloorAssets = false,
    includeAssetHealth = false,
    includePropertyCategories = {},
    requiredProperties = [],
    onlyKnownAssets = false,
    knownAssetUuids = [],
    startDate = DateTime.fromMillis(0),
    endDate = DateTime.fromMillis(0),
    enabled = true,
    subscribe = false
  }: UseBuildingAssetsOptions = {}
): UseBuildingAssetsResult {
  const assets = ref<DecoratedAsset[] | undefined>();
  const loadingError = ref(false);

  const {
    properties: includeProperties = false,
    settings: includeSettings = false,
    miscFields: includeMiscFields = false
  } = unref(includePropertyCategories);

  const params = computed(() => {
    let startDateVal = unref(startDate);
    let endDateVal = unref(endDate);
    if (!unref(buildingUuid) || !startDateVal || !endDateVal) return null;

    if (startDateVal > endDateVal) [startDateVal, endDateVal] = [endDateVal, startDateVal];

    return {
      buildingUuid: unref(buildingUuid),
      includeFloorAssets: unref(includeFloorAssets),
      includeAssetHealth: unref(includeAssetHealth),
      includeProperties: unref(includeProperties),
      includeSettings: unref(includeSettings),
      includeMiscFields: unref(includeMiscFields),
      requiredProperties: unref(requiredProperties),
      startDate: startDateVal.setZone("utc"),
      endDate: endDateVal.endOf("day").setZone("utc"),
      timestamp: DateTime.local() // Ignored, but ensures that variables are never the same
    };
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      ${deviceLock}
      query BuildingAssetsQuery(
        $buildingUuid: ID!
        $includeFloorAssets: Boolean!
        $includeAssetHealth: Boolean!
        $includeProperties: Boolean!
        $includeSettings: Boolean!
        $includeMiscFields: Boolean!
        $requiredProperties: [String!]!
        $startDate: DateTime!
        $endDate: DateTime!
      ) {
        assets: buildingAssets(
          buildingUuid: $buildingUuid
          includeFloorAssets: $includeFloorAssets
          requiredProperties: $requiredProperties
        ) {
          assetUuid
          assetCategory {
            name
          }
          assetType {
            name
          }
          manufacturer {
            name
          }
          assetModel {
            name
          }
          floor {
            position
            name
          }
          name
          knownAssetUuid
          assetCategoryUuid
          assetTypeUuid
          manufacturerUuid
          assetModelUuid
          installationDate
          availableProperties
          smart
          serialNumber
          floorUuid
          floorX
          floorY
          assetHealth(startDate: $startDate, endDate: $endDate) @include(if: $includeAssetHealth) {
            normalTimePct
            propertyName
          }
          ... on Device {
            devEui
            properties @include(if: $includeProperties)
            settings @include(if: $includeSettings)
            miscFields @include(if: $includeMiscFields)
            thresholds {
              values
            }
            ...DeviceLockFields
          }
        }
      }
    `,
    params,
    () => ({
      enabled: unref(enabled) && params.value !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      let gqlAssets: GqlAsset[] = queryResult.data.assets;

      if (unref(onlyKnownAssets)) {
        gqlAssets = gqlAssets.filter(a => a.knownAssetUuid);
      }

      const knownAssetUuidsValue = unref(knownAssetUuids);
      if (knownAssetUuidsValue.length > 0) {
        gqlAssets = gqlAssets.filter(a => knownAssetUuidsValue.includes(a.knownAssetUuid));
      }

      assets.value = gqlAssets.map(asset => decorateAsset(asset));
    }
  });

  if (subscribe) useAssetSubscription(assets);
  return { assets, loadingError, refetch, loading };
}

export interface UseLinkedAssetsOptions {
  includeBuilding?: MaybeRef<boolean>;
  includeChildLinks?: MaybeRef<LinkSelectorOrBoolean<ChildLink>>;
  includeParentLinks?: MaybeRef<LinkSelectorOrBoolean<ParentLink>>;
  includeSiblingLinks?: MaybeRef<LinkSelectorOrBoolean<ChildLink>>;
  cache?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
  subscribe?: boolean;
}

export interface UseLinkedAssetsResult {
  childAssets: Ref<DecoratedAsset[]>;
  parentAssets: Ref<DecoratedAsset[]>;
  firstParentAsset: Ref<DecoratedAsset | undefined>;
  siblingAssets: Ref<DecoratedAsset[]>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useLinkedAssets(
  assetUuid: string,
  {
    includeBuilding = false,
    includeChildLinks = false,
    includeParentLinks = false,
    includeSiblingLinks = false,
    cache = false,
    enabled = true,
    subscribe = false
  }: UseLinkedAssetsOptions = {}
): UseLinkedAssetsResult {
  const rawAsset: Ref<Asset | undefined> = ref();
  const asset: Ref<DecoratedAsset | undefined> = ref();
  const loadingError = ref(false);

  const childAssets = computed<DecoratedAsset[]>(() => {
    if (!asset.value) return [];
    const selector = unref(includeChildLinks);
    const filter = !isBoolean(selector) ? selector.filter : undefined;
    return getChildAssets(asset.value, filter);
  });

  const parentAssets = computed<DecoratedAsset[]>(() => {
    if (!asset.value) return [];
    const selector = unref(includeParentLinks);
    const filter = !isBoolean(selector) ? selector.filter : undefined;
    return getParentAssets(asset.value, filter);
  });

  const firstParentAsset = computed<DecoratedAsset | undefined>(() => {
    return parentAssets.value[0];
  });

  const siblingAssets = computed<DecoratedAsset[]>(() => {
    if (!firstParentAsset.value) return [];
    const selector = unref(includeSiblingLinks);
    const filter = !isBoolean(selector) ? selector.filter : undefined;
    const parentsChildren = getChildAssets(firstParentAsset.value, filter);
    return parentsChildren.filter(a => a.assetUuid !== assetUuid);
  });

  const linkedAssets = computed(() => {
    return compact([...childAssets.value, ...parentAssets.value, ...siblingAssets.value]);
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      ${deviceLock}
      query LinkedAssetsQuery(
        $assetUuid: ID!
        $includeBuilding: Boolean!
        $includeChildLinks: Boolean!
        $includeParentLinks: Boolean!
        $includeSiblingLinks: Boolean!
      ) {
        asset: asset(assetUuid: $assetUuid) {
          parentLinks @include(if: $includeParentLinks) {
            linkType
            parentAsset {
              assetUuid
              knownAssetUuid
              assetCategory {
                name
              }
              assetType {
                name
              }
              manufacturer {
                name
              }
              assetModel {
                name
              }
              smart
              assetCategoryUuid
              assetTypeUuid
              manufacturerUuid
              assetModelUuid
              name
              serialNumber
              installationDate
              floorX
              floorY
              building @include(if: $includeBuilding) {
                buildingUuid
                name
                timeZone
              }
              ... on Device {
                properties
                settings
                miscFields
                thresholds {
                  values
                }
                ...DeviceLockFields
              }
              childLinks @include(if: $includeSiblingLinks) {
                linkType
                childAsset {
                  assetUuid
                  knownAssetUuid
                  assetCategory {
                    name
                  }
                  assetType {
                    name
                  }
                  manufacturer {
                    name
                  }
                  assetModel {
                    name
                  }
                  smart
                  assetCategoryUuid
                  assetTypeUuid
                  manufacturerUuid
                  assetModelUuid
                  name
                  serialNumber
                  installationDate
                  floorX
                  floorY
                  building @include(if: $includeBuilding) {
                    buildingUuid
                    name
                    timeZone
                  }
                  ... on Device {
                    properties
                    settings
                    miscFields
                    thresholds {
                      values
                    }
                    ...DeviceLockFields
                  }
                }
              }
            }
          }
          childLinks @include(if: $includeChildLinks) {
            linkType
            childAsset {
              assetUuid
              knownAssetUuid
              assetCategory {
                name
              }
              assetType {
                name
              }
              manufacturer {
                name
              }
              assetModel {
                name
              }
              smart
              assetCategoryUuid
              assetTypeUuid
              manufacturerUuid
              assetModelUuid
              name
              serialNumber
              installationDate
              floorX
              floorY
              building @include(if: $includeBuilding) {
                buildingUuid
                name
                timeZone
              }
              ... on Device {
                properties
                settings
                miscFields
                thresholds {
                  values
                }
                ...DeviceLockFields
              }
            }
          }
        }
      }
    `,
    () => ({
      assetUuid: assetUuid,
      includeBuilding: unref(includeBuilding),
      includeChildLinks: !!unref(includeChildLinks),
      includeParentLinks: !!unref(includeParentLinks),
      includeSiblingLinks: !!unref(includeSiblingLinks)
    }),
    () => ({
      ...(unref(cache) ? { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true } : {}),
      enabled: unref(enabled)
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      rawAsset.value = queryResult.data.asset;
      asset.value = decorateAsset(queryResult.data.asset);
    }
  });

  if (subscribe) {
    useAssetSubscription(linkedAssets);
  }

  return {
    childAssets,
    parentAssets,
    firstParentAsset,
    siblingAssets,
    loadingError,
    refetch,
    loading
  };
}
