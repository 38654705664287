import { DateTime } from "luxon";

export function getExportInfo(buildingName: string, assetName: string, timeZone: { value: string }): string {
  const formattedDate = DateTime.utc().setZone(timeZone.value).toFormat("yyyy-MM-dd hh:mm a");
  const separator = assetName.length > 0 && buildingName.length > 0 ? " > " : "";
  return `${buildingName}${separator}${assetName}` + "<br/>Exported " + formattedDate;
}

export function formatExportFilename(
  buildingName: string,
  assetName: string,
  timeZone: { value: string },
  propertyName: string
): string {
  const exportDate = DateTime.utc().setZone(timeZone.value).toFormat("yyyy-MM-dd");
  return `${buildingName}_${assetName}_${propertyName}_${exportDate}`.replace(/ /g, "-");
}
